<template>
  <div style="width: 20%">
    <el-upload
      class="avatar-uploader"
      action=""
      :http-request="(params) => uploadImage(params)"
      :on-remove="(file) => removeImage(file)"
      list-type="picture"
      accept="image/jpeg"
      :limit="1"
      :before-upload="beforeAvatarUpload"
    >
      <img v-if="imageUrl" :src="imageUrl" class="avatar" alt="" />
      <img
        :src="this.accountInfo.headImg"
        alt=""
        style="width: 80px; height: 80px"
      />
    </el-upload>
    <el-alert
      title="文件上传成功"
      type="success"
      v-if="successAlertDisabled"
      show-icon
    />
    <el-alert
      title="文件上传失败"
      type="error"
      v-if="errorAlertDisabled"
      show-icon
    />
    <span style="height: 50px">点击可以修改头像</span>
  </div>
  <div style="width: 40%">
    <el-descriptions class="margin-top" title="个人信息" :column="2" border>
      <el-descriptions-item>
        <template #label>
          <div class="cell-item">
            <el-icon style="margin-right: 6px">
              <user />
            </el-icon>
            用户名
          </div>
        </template>
        {{ this.accountInfo.username }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>
          <div class="cell-item">
            <el-icon style="margin-right: 6px">
              <tickets />
            </el-icon>
            会员等级
          </div>
        </template>
        <el-tag size="small">{{ this.accountInfo.auth }}</el-tag>
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>
          <div class="cell-item">
            <el-icon style="margin-right: 6px">
              <iphone />
            </el-icon>
            电话
          </div>
        </template>
        {{ this.accountInfo.phone }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>
          <div class="cell-item">
            <el-icon style="margin-right: 6px">
              <iphone />
            </el-icon>
            邮箱
          </div>
        </template>
        {{ this.accountInfo.mail }}
      </el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script>
import { Iphone, Tickets, User } from "@element-plus/icons-vue";
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      accountInfo: (state) => state.Account.accountInfo,
    }),
  },
  components: {
    Iphone,
    Tickets,
    User,
  },
  data() {
    return {
      imageUrl: "",
      successAlertDisabled: false,
      errorAlertDisabled: false,
    };
  },
  methods: {
    uploadImage(params) {
      let uploadData = new FormData();
      uploadData.append("file", params.file);
      this.$store.dispatch("uploadImg", uploadData);
      setTimeout(() => {
        this.successAlertDisabled = false;
        this.errorAlertDisabled = false;
        console.log(this.accountInfo.headImg);
        if (this.accountInfo.headImg !== "") {
          this.successAlertDisabled = true;
        } else {
          this.errorAlertDisabled = true;
        }
      }, 6000);
    },
    removeImage(file) {
      console.log(file);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
  },
};
</script>

<style scoped>
.avatar-uploader .avatar {
  width: 100px;
  height: 100px;
  display: block;
}
</style>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  text-align: center;
}
.el-descriptions {
  margin-top: 20px;
}
.cell-item {
  display: flex;
  align-items: center;
}
.margin-top {
  margin-top: 20px;
}
</style>
